import React from 'react'
import tw, { styled } from 'twin.macro'
import { Link } from 'gatsby'
import Layout from '../containers/Layout/Layout'
import Seo from '../components/Seo'
import BreadCrumbArrow from '../assets/svgs/breadcrumb-arrow.svg'
import QsheImage from '../assets/images/qshe-cover.png'
import PageCover from '../components/PageCover'
import NewsLetter from '../components/NewsLetter'

import GenericHeading from '../components/GenericHeading'

import QsheDotsImage from '../assets/images/service-dots.png'

import QualityContentImage from '../assets/images/quality-policy-statement.png'
import HsePolicyImage from '../assets/images/hse-policy-statement.png'
import ContentPolicyImage from '../assets/images/content-development-policy.png'

import QsheVisualImageSrc from '../assets/images/qshe-visual-image.png'


const LogoContainer = styled.div`
  ${tw`max-w-screen-xl grid gap-y-10 md:gap-y-0 grid-cols-1 md:grid-cols-3 mx-auto px-5 pb-24 md:px-10 xl:px-20`}

  @media only screen and (min-width: 768px) {
    column-gap: 97px;
  }
`
const ImageBadge = styled.img`
  
  ${tw`h-auto w-full`};

  @media only screen and (min-width: 768px) {
    cursor: pointer;
    transition: all .5s;
    
    &:hover {
    transform: scale(2);
    z-index: 10;
    }
  }
  
`;


const QsheTop = styled.section`
  ${tw`md:flex max-w-screen-xl mx-auto items-center justify-between px-5 py-9 md:px-10 xl:px-20 lg:py-14 xl:py-24`}
`

const QsheContent = styled.div`
  ${tw`mb-16 md:mb-0 w-full md:w-5/12`};
  @media only screen and (min-width: 1280px) {
    width: 547px;
  }
`

const QsheVisual = styled.div`
  ${tw`w-full md:w-1/2 h-auto flex justify-end relative`};
  @media only screen and (min-width: 1280px) {
    width: 470px;
    height: 380.81px;
  }
  
`

const QsheVisualImage = styled.img`
  ${tw`h-auto w-11/12`}
  @media only screen and (min-width: 1280px) {
    width: 422px;
    height: 350px;
  }
`

const QsheDots = styled.img`
  ${tw`absolute left-0 bottom-0`}
  z-index: -1;
`

const QsheText = styled.p`
  ${tw`font-lato text-brand-dark-200 text-xl md:text-2xl leading-snug`};
  margin-bottom: ${props => props.marginBottom};
`

const Qhse = () => {
  return (
    <Layout>
      <Seo title="Quality Management and HSE Policy" />
      <PageCover 
        imageSrc={QsheImage}
        coverTitle="QHSE"
      >
        <Link to="/">Home</Link>
        <div>
          <BreadCrumbArrow />
        </div>
        <Link to="/qshe">QHSE</Link>
      </PageCover>

      <QsheTop>
        <QsheContent>
          <GenericHeading heading="Top Quality Integrated Engineering Services" marginBottom="25px"/>
          <QsheText marginBottom="20px">
            We are committed toward the delivery of top-quality integrated engineering services as enshrined in our company policies and core values.
          </QsheText>
          <QsheText marginBottom="20px">
            We strive to conform to all applicable standards and regulations with keen emphasis to the protection of Health, Safety and Environment.
          </QsheText>
          <QsheText>
            We ensure conformance with Local content Act through training and retraining of our employees.
          </QsheText>
        </QsheContent>
        <QsheVisual>
          <QsheVisualImage src={QsheVisualImageSrc} alt="Engineers at a site" />
          <QsheDots src={QsheDotsImage} alt="Dots" />
        </QsheVisual>
      </QsheTop>

      <LogoContainer>
        <ImageBadge src={QualityContentImage}/>
        <ImageBadge src={HsePolicyImage}/>
        <ImageBadge src={ContentPolicyImage}/>
      </LogoContainer>
      
      <NewsLetter />

    </Layout>
  )
}

export default Qhse;